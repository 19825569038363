<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="8">
        <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
          <v-card-text style="background-color: #f1f4f9">
            <v-row>
              <v-col cols="12" md="4" class="no-padding ml-n2">
                <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                  <v-card-title>
                    <v-img
                      :src="require('@/assets/images/logos/anavel_logo.png')"
                      max-height="150px"
                      max-width="120px"
                      alt="logo"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-title class="text-center justify-center muller-capitalized"
                    >Mes Services Assurance</v-card-title
                  >
                  <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL </v-card-subtitle>
                  <v-card-text class="no-padding">
                    <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="8" class="no-padding d-flex">
                <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                  <v-card-title class="text-center justify-center">Paiement annulé</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <i class="fa-solid fa-circle-xmark EoleYellow--text fa-8x"></i>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text class="text-center">
                    Votre paiement a été annulé. Vous pouvez réessayer ou contacter le support pour plus d'informations.
                  </v-card-text>
                  <v-card-text class="text-center"> Anavel vous remercie pour votre confiance. </v-card-text>
                  <v-spacer></v-spacer>
                  <v-card-actions class="mt-10 text-center mx-auto">
                    <v-btn text @click="goBack">Retour</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.push("/") // Ajustez le chemin selon la logique de votre application
    },
  },
}
</script>
